export enum TypeTutor {
  TUTOR = 'TUTOR', // assistantTeachers, assistantSubstituteTeachers
  TUTOR_SYNCHRONOUS = 'TUTOR_SYNCHRONOUS', // syncAssistants // tutor sincronico
  TUTOR_REVIEWER = 'TUTOR_REVIEWER', // reviewerAssistants// tutor corrector, ver si es el mejor nombre
  TUTOR_CODER_ASK = 'TUTOR_CODER_ASK', // coderAskAssistants,
}

export class Member {
  constructor (
    public id: string,
    public name: string,
    public lastName: string,
    public email: string,
    public country: string
  ) {}
}

export class Tutor extends Member {
  constructor (
    id: string,
    name: string,
    lastName: string,
    email: string,
    country: string,
     public type?: TypeTutor,
     public tutorStudentId?: string
  ) {
    super(id, name, lastName, email, country)
  }

  get fullName (): string {
    return this.name + ' ' + this.lastName
  }
}

export class Student extends Member {
  constructor (
    public id: string,
    public name: string,
    public lastName: string,
    public email: string,
    public country: string,
    public assignatedAt: Date = new Date(),
    public _unassignatedAt?: Date,
    public fromTutorId?: string
  ) {
    super(id, name, lastName, email, country)
  }
}

export class TutorStudents {
  constructor (
    public commissionId: string,
    public commissionNumber: number,
    public tutor: Tutor,
    public students: Student[],
    public id: string,
    public assignatedAt: Date = new Date(),
    public unassignatedAt?: Date,
    public createdAt: Date = new Date(),
    public updatedAt: Date = new Date(),
    public __v: number = 0
  ) {
    this.students = students.filter(student => !student._unassignatedAt).map((student) => {
      return new Student(
        student.id,
        student.name,
        student.lastName,
        student.email,
        student.country,
        student.assignatedAt,
        student._unassignatedAt,
        student.fromTutorId
      )
    })
    this.tutor = new Tutor(tutor.id, tutor.name, tutor.lastName, tutor.email, tutor.country, tutor.type, id)
  }
}
