import { Api } from './api'
import { TutorStudentGroups } from '@/models/commission-tutor-students-group/classes/TutorStudentsGroup'

class CommissionTutorStudentsGroupAPI {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private returnTutorStudentsList(
    tutorStudentsData: TutorStudentGroups[]
  ): Array<TutorStudentGroups> {
    const tutorStudentGroups = new Array<TutorStudentGroups>()
    tutorStudentsData.forEach((element) => {
      tutorStudentGroups.push(
        new TutorStudentGroups(
          element.commissionId,
          element.commissionNumber,
          element.account,
          element.tutor,
          element.students,
          element.id,
          element.createdAt,
          element.updatedAt,
          element.__v,
          element.alias
        )
      )
    })
    return tutorStudentGroups
  }

  public async createGroup(tutoringGroup: TutorStudentGroups) {
    const data = await Api.post<TutorStudentGroups[]>(
      `${this.baseURL}/v1/commission-tutor-student-group`,
      tutoringGroup
    )
    return data
  }

  public async getCommissionTutorStudentsGroup(
    commissionNumber: number
  ): Promise<TutorStudentGroups[]> {
    const data = await Api.get<TutorStudentGroups[]>(
      `${this.baseURL}/v1/commission-tutor-student-group/by-commission-number/${commissionNumber}`
    )
    return this.returnTutorStudentsList(data)
  }

  public async pushStudentInTutoringGroup(
    commissionTutorStudentId: string,
    students: string[]
  ): Promise<void> {
    return await Api.put<void>(
      `${this.baseURL}/v1/commission-tutor-student-group/${commissionTutorStudentId}/push-student`,
      { students }
    )
  }

  public async deleteStudentsInTutoringGroup(
    commissionTutorStudentId: string,
    students: string[]
  ): Promise<void> {
    return await Api.put<void>(
      `${this.baseURL}/v1/commission-tutor-student-group/${commissionTutorStudentId}/remove-student`,
      { students }
    )
  }

  public async deleteGroup(commissionTutorStudentId: string): Promise<void> {
    return await Api.del<void>(
      `${this.baseURL}/v1/commission-tutor-student-group/${commissionTutorStudentId}`
    )
  }

  public async updateGroup(
    commissionTutorStudentId: string,
    updatePayload: { alias: string; user: string; password: string }
  ) {
    return await Api.put<void>(
      `${this.baseURL}/v1/commission-tutor-student-group/${commissionTutorStudentId}`,
      updatePayload
    )
  }
}

export const CommissionTutorStudentsGroupApi =
  new CommissionTutorStudentsGroupAPI()
