import { Api } from './api'
import { TutorStudents } from '@/models/commission-tutor-students/classes/TutorStudents'

class CommissionTutorStudentsAPI {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private returnTutorStudentsList(
    tutorStudentsData: TutorStudents[]
  ): Array<TutorStudents> {
    const tutorStudents = new Array<TutorStudents>()
    tutorStudentsData.forEach((element) => {
      tutorStudents.push(
        new TutorStudents(
          element.commissionId,
          element.commissionNumber,
          element.tutor,
          element.students,
          element.id,
          element.assignatedAt,
          element.unassignatedAt,
          element.createdAt,
          element.updatedAt,
          element.__v
        )
      )
    })
    return tutorStudents
  }

  public async getCommissionTutorStudents(
    commissionNumber: number
  ): Promise<TutorStudents[]> {
    const data = await Api.get<TutorStudents[]>(
      `${this.baseURL}/v1/commission-tutor-students/by-commission-number/${commissionNumber}`
    )
    return this.returnTutorStudentsList(data)
  }

  public async pushStudentInTutoring(
    commissionTutorStudentId: string,
    students: string[]
  ): Promise<void> {
    return await Api.put<void>(
      `${this.baseURL}/v1/commission-tutor-students/${commissionTutorStudentId}/push-student`,
      { students }
    )
  }

  public async deleteStudentsInTutoring(
    commissionTutorStudentId: string,
    students: string[]
  ): Promise<void> {
    return await Api.put<void>(
      `${this.baseURL}/v1/commission-tutor-students/${commissionTutorStudentId}/remove-student`,
      { students }
    )
  }
}

export const CommissionTutorStudentsApi = new CommissionTutorStudentsAPI()
