import { AccessToken } from '@/models/access-token/interfaces/accessToken'
import { Api } from './api'

class AccessTokenApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async createAccessToken(
    user: string,
    email: string,
    createdBy: string
  ): Promise<AccessToken> {
    return Api.post<AccessToken>(`${this.baseURL}/v1/access-token`, {
      user,
      email,
      createdBy
    })
  }
}

export const AccessTokenAPI = new AccessTokenApi()
