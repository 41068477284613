
export class Member {
  constructor (
    public id: string,
    public name: string,
    public lastName: string,
    public email: string,
    public country: string
  ) {}
}

export class Student extends Member {
  constructor (
    public id: string,
    public name: string,
    public lastName: string,
    public email: string,
    public country: string,
    public assignatedAt: Date = new Date(),
    public _unassignatedAt?: Date,
    public fromTutorId?: string
  ) {
    super(id, name, lastName, email, country)
  }
}

class Account {
  constructor (readonly id?: string, readonly user?: string, readonly password?: string) {}
}

export class TutorStudentGroups {
  constructor (
    readonly commissionId: string,
    readonly commissionNumber: number,
    readonly account: Account,
    readonly tutor: Member,
    readonly students: Student[],
    readonly id?: string,
    readonly createdAt?: Date,
    readonly updatedAt?: Date,
    readonly __v?: number,
    readonly alias?: string
  ) {
    this.students = students.filter(student => !student._unassignatedAt).map((student) => {
      return new Student(
        student.id,
        student.name,
        student.lastName,
        student.email,
        student.country,
        student.assignatedAt,
        student._unassignatedAt,
        student.fromTutorId
      )
    })
    this.tutor = new Member(tutor.id, tutor.name, tutor.lastName, tutor.email, tutor.country)
  }
}
