
import {
  IHomeworkDeliveryStats,
  Student
} from '@/models/course/builder/student.builder'
import { Person } from '@/models/person/classes/Person'
import { Country } from '@/models/countries/classes/Country'
import { StudentCourseModality } from '@/models/course/enums'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import { CSSColors, TypeStudents, StudentTypeAlert } from '@/models/enums'
import { TypeStudentNameAndColor } from '@/models/course/maps'
import { truncate } from '../../../utils/math'
import { HomeworkStatus, HomeworkType } from '@/models/academic-definitions/enums'
import HomeworkModal from '@/components/modals/HomeworkDeliveriesModal.vue'
import { LevelAPI } from '@/api/level.api'
import { AccessTokenAPI } from '@/api/access-token.api'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { Tutoring } from '@/models/course/classes/Tutoring'

export default defineComponent({
  components: {
    HomeworkModal
  },
  props: {
    students: Map as PropType<Map<string, Student[]>>,
    loadingStudents: Boolean,
    challengeStats: Map as PropType<Map<string, IHomeworkDeliveryStats>>,
    courseId: {
      type: String,
      default: null
    },
    tutoring: {
      type: Array as PropType<Array<Tutoring>>,
      default: null
    },
    courseNumber: {
      type: Number,
      default: null
    },
    tutors: Map as PropType<Map<string, Person>>
  },
  setup (props) {
    const session = useUserSessionStore()
    const personId = ref<string | null>(null)
    const homeworkType = ref<HomeworkType | null>(null)
    const homeworkStatus = ref<HomeworkStatus | null>(null)
    const showHomeworkModal = ref(false)
    const showHeader = ref<boolean>()
    const courseNumber = computed<number>(() => props.courseNumber)

    const impersonateStaff = async (data: Person) => {
      const PLATFORM_URL = process.env.PLATFORM_URL
      try {
        const [level, accessToken] = await Promise.all([LevelAPI.getUrl(props.courseId), await AccessTokenAPI.createAccessToken(data.id, data.email, session.currentUser._id)])
        window.open(`${PLATFORM_URL}?to-camada=${courseNumber.value}&to-course-slug=${level.url}&to-section=delivers&tkn=${accessToken.token}`, '_blank')
      } catch (error) {
        console.log(error)
      }
    }

    const studentsByTeacher = computed<Map<string, Student[]>>(() => props.students as Map<string, Student[]>)
    const expandedRows = ref<Person[]>()
    return {
      studentsByTeacher,
      expandedRows,
      Country,
      StudentCourseModality,
      truncate,
      CSSColors,
      StudentTypeAlert,
      showHomeworkModal,
      showHeader,
      async setShowHomeworkModal (id: string, type: HomeworkType, status: HomeworkStatus | null = null) {
        personId.value = id
        showHomeworkModal.value = !showHomeworkModal.value
        showHeader.value = false
        homeworkType.value = type
        homeworkStatus.value = status
      },
      personId,
      homeworkType,
      HomeworkType,
      window,
      impersonateStaff,
      homeworkStatus,
      HomeworkStatus
    }
  },
  methods: {
    getTypeStudent: (type: TypeStudents): Record<string, string> => {
      return (
        TypeStudentNameAndColor.get(type) || {
          name: 'Sin estado',
          color: CSSColors.White
        }
      )
    }
  }
})
