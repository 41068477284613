import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-weight-700" }
const _hoisted_2 = { class: "p-d-flex p-jc-between" }
const _hoisted_3 = { class: "font-weight-700" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 1,
  class: "pi pi-question-circle"
}
const _hoisted_6 = { class: "p-d-flex p-jc-center" }
const _hoisted_7 = { class: "p-d-flex p-jc-center" }
const _hoisted_8 = { class: "p-d-flex p-jc-center" }
const _hoisted_9 = { class: "p-d-flex p-jc-center" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Column = _resolveComponent("Column")!
  const _component_CoderBadge = _resolveComponent("CoderBadge")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_HomeworkModal = _resolveComponent("HomeworkModal")!
  const _directive_parentclass = _resolveDirective("parentclass")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.tutors || _ctx.loadingStudents)
      ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
      : (_openBlock(), _createBlock(_component_DataTable, {
          key: 1,
          class: "p-datatable-ssm no-border-radius-header tutors-table",
          rowGroupMode: "subheader",
          value: Array.from(_ctx.tutors.values()),
          responsiveLayout: "scroll",
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandedRows = $event))
        }, {
          expansion: _withCtx(({ data }) => [
            _createVNode(_component_DataTable, {
              class: "p-datatable-ssm column-header no-border-header students-table",
              value: _ctx.studentsByTeacher.get(data.id),
              responsiveLayout: "scroll"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ColumnGroup, { type: "header" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          header: "Estudiante",
                          headerClass: "header-thin",
                          rowspan: 2,
                          colspan: 3
                        }),
                        _createVNode(_component_Column, {
                          header: "Estado",
                          headerClass: "header-thin jc-center",
                          rowspan: 2
                        }),
                        _createVNode(_component_Column, {
                          header: "Avisos",
                          headerClass: "header-thin jc-center",
                          rowspan: 2
                        }),
                        _createVNode(_component_Column, {
                          header: "Asistencia",
                          headerClass: "header-thin jc-center",
                          rowspan: 2
                        }),
                        _createVNode(_component_Column, {
                          header: "Desafios",
                          headerClass: "header-thin jc-center",
                          colspan: 4
                        }),
                        _createVNode(_component_Column, {
                          header: "PreEntregas",
                          headerClass: "header-thin jc-center",
                          colspan: 4
                        }),
                        _createVNode(_component_Column, {
                          header: "Proyecto Final",
                          headerClass: "header-thin jc-center",
                          rowspan: 2
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          header: "Aprobados",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Entregados",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Faltantes",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Pendientes",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Aprobados",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Entregados",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Faltantes",
                          headerClass: "header-thin jc-center"
                        }),
                        _createVNode(_component_Column, {
                          header: "Pendientes",
                          headerClass: "header-thin jc-center"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { field: "profile.name" }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(data.profile?.name) + " " + _toDisplayString(data.profile?.lastName), 1)
                    ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, {
                  field: "profile.country",
                  bodyClass: "p-text-center"
                }, {
                  body: _withCtx(({ data }) => [
                    (data.profile?.country && _ctx.Country.getCountryNameFromCode(data.profile?.country) !== '')
                      ? _withDirectives((_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.Country.getIconUrlFromCountryCode(data.profile?.country),
                          alt: `Bandera de ${_ctx.Country.getCountryNameFromCode(data.profile?.country)}`
                        }, null, 8, _hoisted_4)), [
                          [
                            _directive_tooltip,
                            _ctx.Country.getCountryNameFromCode(data.profile?.country),
                            void 0,
                            { blur: true }
                          ]
                        ])
                      : _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_5, null, 512)), [
                          [
                            _directive_tooltip,
                            'Desconocido',
                            void 0,
                            { blur: true }
                          ]
                        ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { field: "data.user.courseModality" }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_CoderBadge, {
                        text: `${ data.user?.courseModality === _ctx.StudentCourseModality.Beca ? 'Beca' : 'Pro'}`,
                        backgroundColor: `${ data.user?.courseModality === _ctx.StudentCourseModality.Beca ? '#393B43' : '#5140AA'}`,
                        fontSize: '12px',
                        textColor: '#FFFFFF',
                        rounded: "",
                        padding: '4px 8px'
                      }, null, 8, ["text", "backgroundColor"])
                    ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { field: "user.typeStudent" }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_CoderBadge, {
                        text: _ctx.getTypeStudent(data.user?.type).name,
                        backgroundColor: _ctx.getTypeStudent(data.user?.type).color,
                        fontSize: '12px',
                        rounded: "",
                        padding: '4px 8px',
                        class: _normalizeClass('p-ml-0')
                      }, null, 8, ["text", "backgroundColor"])
                    ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { field: "user.alert" }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_8, [
                      (data.alert)
                        ? (_openBlock(), _createBlock(_component_CoderBadge, {
                            key: 0,
                            text: `Incumplió (${data.alert.type === _ctx.StudentTypeAlert.WarnDelivers ? '1' : '2'})`,
                            backgroundColor: _ctx.CSSColors.LightRedBrain,
                            fontSize: '12px',
                            rounded: "",
                            padding: '4px 8px'
                          }, null, 8, ["text", "backgroundColor"]))
                        : _createCommentVNode("", true),
                      (data.alert && data.alert.type === _ctx.StyudentTypeAlert.AlertDelivers)
                        ? (_openBlock(), _createBlock(_component_CoderBadge, {
                            key: 1,
                            text: 'Purga',
                            backgroundColor: _ctx.CSSColors.RedBrain,
                            fontSize: '12px',
                            rounded: "",
                            padding: '4px 8px'
                          }, null, 8, ["backgroundColor"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { headerClass: "header-thin p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    _createElementVNode("div", _hoisted_9, [
                      (data.academicStatus?.assistance)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.truncate(data.academicStatus?.assistance)) + "% ", 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_11, "-"))
                    ])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.Regular,
                      _ctx.HomeworkStatus.Approved
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).regular.approved), 9, _hoisted_13)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_14, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.Regular
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).regular.delivered), 9, _hoisted_16)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.Regular,
                      _ctx.HomeworkStatus.PendingDelivery
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).regular.deliveryPending), 9, _hoisted_19)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_20, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.Regular,
                      _ctx.HomeworkStatus.OnHold
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).regular.onHold), 9, _hoisted_22)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_23, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_24, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.PreFinal,
                      _ctx.HomeworkStatus.Approved
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).preFinal.approved), 9, _hoisted_25)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_26, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.PreFinal
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).preFinal.delivered), 9, _hoisted_28)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_29, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.PreFinal,
                      _ctx.HomeworkStatus.PendingDelivery
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).preFinal.deliveryPending), 9, _hoisted_31)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_32, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                          _createElementVNode("u", {
                            onClick: 
                  () =>
                    _ctx.setShowHomeworkModal(
                      data.user?.personId,
                      _ctx.HomeworkType.PreFinal,
                      _ctx.HomeworkStatus.OnHold
                    )
                ,
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).preFinal.onHold), 9, _hoisted_34)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_35, "-"))
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_Column, { bodyClass: "p-text-center" }, {
                  body: _withCtx(({ data }) => [
                    (_ctx.challengeStats.get(data.user?.personId))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_36, [
                          _createElementVNode("u", {
                            onClick: () => _ctx.setShowHomeworkModal(data.user?.personId, _ctx.HomeworkType.Final),
                            class: "clickable"
                          }, _toDisplayString(_ctx.challengeStats.get(data.user?.personId).final.onHold
                  ? 'Pendiente'
                  : _ctx.challengeStats.get(data.user?.personId).final.grade === -1
                  ? 'Caducó'
                  : _ctx.challengeStats.get(data.user?.personId).final.deliveryPending
                  ? 'Faltante'
                  : _ctx.challengeStats.get(data.user?.personId).final.grade), 9, _hoisted_37)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_38, "-"))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["value"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              expander: true,
              headerStyle: "width: 1rem",
              class: "bg-gray-300",
              headerClass: "header-thin no-border"
            }),
            _createVNode(_component_Column, {
              field: "fullName",
              header: "Tutor",
              headerClass: "header-thin",
              class: "p-col-2"
            }, {
              body: _withCtx(({ data }) => [
                _withDirectives(_createElementVNode("span", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(data.fullName), 1)
                ], 512), [
                  [_directive_parentclass, 'bg-gray-300']
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "assignedStudents",
              header: "Cantidad de asignados",
              headerClass: "header-thin"
            }, {
              body: _withCtx(({ data }) => [
                _createElementVNode("div", _hoisted_2, [
                  _withDirectives(_createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_CoderBadge, {
                      text: 
                `${_ctx.studentsByTeacher.get(data.id)?.length} ${
                  _ctx.studentsByTeacher.get(data.id)?.length === 1
                    ? 'Estudiante'
                    : 'Estudiantes'
                }`
              ,
                      backgroundColor: _ctx.studentsByTeacher.get(data.id)?.length > 0 ? 'rgba(163, 200, 131, 0.6)' : 'rgba(248, 131, 131, 0.6)',
                      fontSize: '12px'
                    }, null, 8, ["text", "backgroundColor"])
                  ], 512), [
                    [_directive_parentclass, 'bg-gray-300']
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_Button, {
                      onClick: ($event: any) => (_ctx.impersonateStaff(data)),
                      class: "p-button-primary p-button-text clase-button",
                      icon: "pi pi pi-external-link",
                      label: "Ir a la plataforma"
                    }, null, 8, ["onClick"])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "expandedRows"])),
    _createVNode(_component_HomeworkModal, {
      visible: _ctx.showHomeworkModal,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showHomeworkModal = $event)),
      showHeader: _ctx.showHeader,
      courseId: _ctx.courseId,
      personId: _ctx.personId,
      homeworkType: _ctx.homeworkType,
      homeworkStatus: _ctx.homeworkStatus,
      style: {"width":"70vw","height":"50%"}
    }, null, 8, ["visible", "showHeader", "courseId", "personId", "homeworkType", "homeworkStatus"])
  ], 64))
}