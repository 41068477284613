
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from 'vue'
import CourseStudents from '@/components/courses/tables/CourseStudents.vue'
import { IHomeworkDeliveryStats, Student } from '@/models/course/builder/student.builder'
import { Course } from '@/models/course/classes/Course'
import { IPowerBiTeachersAccountData } from '@/models/course/interfaces'
import { Tutor, TutorStudents } from '@/models/commission-tutor-students/classes/TutorStudents'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { StudentAPI } from '@/api/students.api'
import { CourseAPI } from '@/api/course.api'
import { CommissionTutorStudentsApi } from '@/api/commission-tutor-students.api'
import { CommissionTutorStudentsGroupApi } from '@/api/commission-tutor-students-group.api'
import { CourseStatus, ConfigurationTag } from '@/models/course/enums'
import Unassigned from './tutorAssigment-tabs/Unassigned.vue'
import Groups from './tutorAssigment-tabs/Groups.vue'
import { TutorStudentGroups, Member } from '@/models/commission-tutor-students-group/classes/TutorStudentsGroup'
export default defineComponent({
  components: {
    CourseStudents,
    Unassigned,
    Groups
  },
  props: {
    students: {
      type: Array as PropType<Array<Student>>
    },
    course: {
      type: Course
    }
  },
  setup (props) {
    const tutoring: Ref<TutorStudents[]> = ref([])
    const tutoringGroup: Ref<TutorStudentGroups[]> = ref([])
    const toast = useToast()
    const studentsRef: Ref<Student[]> = ref([])
    const studentsIds: Ref<string[]> = ref([])
    const challengeStats: Ref<Map<string, IHomeworkDeliveryStats>> = ref(new Map())
    const courseRef = computed<Course>(() => props.course as Course)
    const loadingStudents = ref<boolean>(false)
    const courseId = computed<string>(() => (props.course as Course)?._id)
    const teachersPowerBi = computed<IPowerBiTeachersAccountData>(() => (props.course as Course)?.powerBi)
    const isNotStarted = computed<boolean>(() => courseRef.value.status === CourseStatus.NotStarted)

    const studentsByTutor = computed<Map<string, Student[]>>(() => {
      const assigned = Student.convertToTutorStudentsMap(studentsRef.value as Student[], true, tutoring.value)
      return assigned
    })
    const getTutors = async () => {
      return new Map(Array.from(tutoring.value).map(tutorStudent => [tutorStudent.tutor.id, tutorStudent.tutor]))
    }
    const getTutorsGroup = async () => {
      return new Map(Array.from(tutoringGroup.value).map(tutorStudentGroup => [tutorStudentGroup.tutor.id, tutorStudentGroup.tutor]))
    }

    const tutors = ref<Map<string, Tutor>>()
    const tutorsGroup = ref<Map<string, Member>>()
    watch(tutoring, async () => {
      tutors.value = await getTutors()
    })

    const getStudents = async () => {
      try {
        loadingStudents.value = true
        tutoring.value = await CommissionTutorStudentsApi.getCommissionTutorStudents(courseRef.value?.id)
        tutoringGroup.value = await CommissionTutorStudentsGroupApi.getCommissionTutorStudentsGroup(courseRef.value?.id)
        studentsRef.value = await StudentAPI.findAll(courseRef.value._id)
      } catch (e) {
        toast.add({
          severity: ToastSeverities.Error,
          summary: ToastSummaries.Error,
          detail: 'Error al buscar estudiantes',
          life: ToastLife.Default
        })
      } finally {
        loadingStudents.value = false
      }
    }

    onMounted(async () => {
      tutoring.value = await CommissionTutorStudentsApi.getCommissionTutorStudents(courseRef.value?.id)
      tutoringGroup.value = await CommissionTutorStudentsGroupApi.getCommissionTutorStudentsGroup(courseRef.value?.id)
      studentsRef.value = props.students as Student[]
      if (!studentsRef.value) { // En caso de que los estudiantes aún no fueron cargados
        await getStudents()
      }
      try {
        loadingStudents.value = true
        studentsRef.value.map(s => studentsIds.value.push(s.user?.personId as string))
        challengeStats.value = await CourseAPI.getCourseHomeworkStatsByStudent(courseRef.value?._id)
        tutors.value = await getTutors()
        tutorsGroup.value = await getTutorsGroup()
      } catch (e) {
        toast.add({
          severity: ToastSeverities.Error,
          summary: ToastSummaries.Error,
          detail: 'Error al buscar estudiantes',
          life: ToastLife.Default
        })
      } finally {
        loadingStudents.value = false
      }
    })

    return {
      loadingStudents,
      challengeStats,
      courseId,
      teachersPowerBi,
      tutoring,
      tutoringGroup,
      isNotStarted,
      courseNumber: courseRef.value.id,
      studentsByTutor,
      tutors,
      tutorsGroup,
      getStudents,
      ConfigurationTag
    }
  }
})
