import { Api } from './api'

class LevelApi {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  public async getUrl(courseId: string): Promise<{ url: string }> {
    return Api.get<{ url: string }>(`${this.baseURL}/v1/level/url`, {
      courseId
    })
  }
}

export const LevelAPI = new LevelApi()
