
import { defineComponent, PropType, ref, computed } from 'vue'
import { Student } from '@/models/course/builder/student.builder'
import { Person } from '@/models/person/classes/Person'
import { Country } from '@/models/countries/classes/Country'
import { StudentCourseModality } from '@/models/course/enums'
import { TypeStudentNameAndColor } from '@/models/course/maps'
import { CSSColors, TypeStudents } from '@/models/enums'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { CommissionTutorStudentsApi } from '@/api/commission-tutor-students.api'
import { Tutor, TutorStudents } from '@/models/commission-tutor-students/classes/TutorStudents'
export default defineComponent({
  props: {
    students: Map as PropType<Map<string, Student[]>>,
    loadingStudents: Boolean,
    courseId: {
      type: String,
      default: null
    },
    tutors: Map as PropType<Map<string, Tutor>>,
    tutoring: Array as PropType<TutorStudents[]>
  },
  emits: ['reloadStudents'],
  setup (props, { emit }) {
    const loading = ref<boolean>(false)
    const confirm = useConfirm()
    const studentsRef = computed<Map<string, Student[]>>(() => props.students as Map<string, Student[]>)
    const selectedUnassignedStudents = ref<Array<Student>>([])
    const selectedAssignedStudent = ref<Array<Student>>([])
    const expandedRows = ref<Person[]>()
    const toast = useToast()
    const unassignedStudent = computed<Student[] | undefined>(() => {
      return studentsRef.value?.get('unassigned')
    })
    const assignedStudent = computed<Map<string, Student[]>>(() => {
      if (studentsRef.value) {
        if (studentsRef.value.has('unassigned')) {
          const assigned = studentsRef.value
          assigned.delete('unassigned')
          return assigned as Map<string, Student[]>
        }
      }
      return studentsRef.value as Map<string, Student[]>
    })
    const unassignStudents = async () => {
      const tutorsStudents = [...props.tutoring?.values() ?? []]

      for (const ts of tutorsStudents) {
        const studentsToUnassign = ts.students.filter(student => selectedAssignedStudent.value.find(selected => selected.user?.personId === student.id)).map(selected => selected.id)
        if (studentsToUnassign.length) {
          await CommissionTutorStudentsApi.deleteStudentsInTutoring(ts.id, studentsToUnassign)
        }
      }
    }
    const assignStudents = async (tutorId: string): Promise<void> => {
      const toAssign = selectedUnassignedStudents.value.map(student => student.user?.personId) as string[]
      return CommissionTutorStudentsApi.pushStudentInTutoring(tutorId, toAssign)
    }
    const getLength = (origin: string) => origin === 'unassigned' ? selectedUnassignedStudents.value?.length : selectedAssignedStudent.value?.length
    const startDrag = (event: DragEvent, origin: string) => {
      if (event.dataTransfer) {
        const amountSelected = getLength(origin)
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.setData('origin', origin)
        // elimino el tooltip
        const tooltipElement = document.getElementById('unassignedDragAndDropTooltip')
        tooltipElement?.remove()
        // creo el tooltip
        const tooltip = document.createElement('span')
        tooltip.setAttribute('class', amountSelected > 0 ? 'dragAndDrop-tooltip' : 'dragAndDrop-tooltip empty')
        tooltip.setAttribute('id', 'unassignedDragAndDropTooltip')
        const text = document.createTextNode(`${amountSelected} ${amountSelected > 1 ? 'Estudiantes' : 'Estudiante'}`)
        tooltip.appendChild(text)
        document.body.append(tooltip)
        event.dataTransfer.setDragImage(tooltip, 0, 0)
      }
    }
    const onDrop = (event: DragEvent, destination: string): boolean | void => {
      const tooltip = document.getElementById('unassignedDragAndDropTooltip')
      tooltip?.remove()
      const origin = event.dataTransfer?.getData('origin')
      // valido q seleccionados sea mayor a 1
      if (!origin || getLength(origin) === 0) {
        return false
      }
      if (origin && ((origin === 'unassigned' && destination !== 'unassigned') || (origin === 'assigned' && destination === 'unassigned'))) {
        const amount = getLength(origin)
        confirm.require({
          group: 'global',
          message: `¿Está seguro que desea ${destination === 'unassigned' ? 'desasignar' : 'asignar'} ${amount} ${amount > 1 ? 'estudiantes' : 'estudiante'} al tutor?`,
          header: `${destination === 'unassigned' ? 'Desasignar' : 'Asignar'} ${amount > 1 ? 'Estudiantes' : 'Estudiante'}`,
          icon: destination === 'unassigned' ? 'pi pi-exclamation-triangle' : undefined,
          accept: async () => {
            loading.value = true
            try {
              if (destination === 'unassigned') {
                await unassignStudents()
              } else {
                await assignStudents(destination)
              }
            } catch (error) {
              toast.add({
                summary: ToastSummaries.Error,
                detail: 'Error al realizar la operacion.',
                severity: ToastSeverities.Error,
                life: ToastLife.Default
              })
            } finally {
              emit('reloadStudents')
              selectedUnassignedStudents.value = []
              selectedAssignedStudent.value = []
              event.dataTransfer?.clearData()
            }
            loading.value = false
          }
        })
      }
    }

    return {
      loading,
      selectedUnassignedStudents,
      selectedAssignedStudent,
      unassignedStudent,
      assignedStudent,
      expandedRows,
      Country,
      StudentCourseModality,
      startDrag,
      onDrop,
      getTypeStudent: (type: TypeStudents): Record<string, string> => {
        return (
          TypeStudentNameAndColor.get(type) || {
            name: 'Sin estado',
            color: CSSColors.White
          }
        )
      }
    }
  }
})
