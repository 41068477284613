
import { defineComponent, PropType, ref, onMounted, watch, toRef, reactive, computed } from 'vue'
import { Person } from '@/models/person/classes/Person'
import { Tutoring } from '@/models/course/classes/Tutoring'
import { IPowerBiTeachersAccountData, ITutoringGroups } from '@/models/course/interfaces'
import { Student } from '@/models/course/builder/student.builder'
import { EducationRouteNames } from '@/router/route-names'
import { useMapper } from '@/hooks/useMapper'
import { CommissionTutorStudentsGroupApi } from '@/api/commission-tutor-students-group.api'
import { TutorStudentGroups, Member } from '@/models/commission-tutor-students-group/classes/TutorStudentsGroup'
export default defineComponent({
  props: {
    courseId: {
      type: String,
      default: null
    },
    courseNumber: {
      type: Number,
      default: null
    },
    tutors: Map as PropType<Map<string, Person>>,
    tutorsGroup: Map as PropType<Map<string, Member>>,
    tutoring: {
      type: Array as PropType<Tutoring[]>,
      required: true,
      default: null
    },
    tutoringGroup: {
      type: Array as PropType<TutorStudentGroups[]>,
      required: true,
      default: null
    },
    students: Map as PropType<Map<string, Student[]>>,
    loadingStudents: Boolean,
    teachersPowerBi: {
      type: Object as PropType<IPowerBiTeachersAccountData>,
      required: true,
      default: () => ({ teachersPowerBiAccount: '', teachersPowerBiPass: '' })
    }
  },
  emits: ['reloadStudents'],
  setup (props, { emit }) {
    const tutorsRef = toRef(props, 'tutors')
    const tutoringGroupRef = toRef(props, 'tutoringGroup')
    const selectedTutor = ref<string>('')
    const teachersPowerBiRef = reactive<IPowerBiTeachersAccountData>({ teachersPowerBiAccount: '', teachersPowerBiPass: '' })
    const tutoring = computed(() => props.tutoring)
    const tutoringMap = useMapper<Tutoring>('tutorId', tutoring.value)
    const displayNewGroup = ref<boolean>(false)
    const isEditing = ref<boolean>(false)
    const editingIdGroup = ref<string>('')
    const editingAliasGroup = ref<string>('')
    const editingAccountUserGroup = ref<string>('')
    const editingAccountPassGroup = ref<string>('')
    const disabledButton = computed<boolean>(() => {
      const { teachersPowerBi } = props
      return teachersPowerBi.teachersPowerBiAccount === teachersPowerBiRef.teachersPowerBiAccount &&
      teachersPowerBi.teachersPowerBiPass === teachersPowerBiRef.teachersPowerBiPass
    })
    const checkboxControl = ref(new Map())

    const groups = ref(new Map<string, ITutoringGroups>())

    const generateGroupData = () => {
      const groupMap = new Map()
      if (selectedTutor?.value && checkboxControl.value.size > 0) {
        const tutoringMapValues = tutoringGroupRef.value?.filter(tg => tg.tutor.id === selectedTutor.value)
        if (tutoringMapValues.length) {
          tutoringMapValues.forEach(tutoringMapValue => {
            const tutoringGroup: ITutoringGroups = {
              alias: tutoringMapValue?.alias || '',
              powerBiAccount: tutoringMapValue?.account?.user || '',
              powerBiPass: tutoringMapValue?.account?.password || '',
              members: tutoringMapValue?.students?.map(st => st.id) || []
            }
            groupMap.set(tutoringMapValue.alias, tutoringGroup)
          })
        }
      }

      return groupMap
    }

    // const groups = computed<Map<string, ITutoringGroups>>(() => {
    //   const groupMap = new Map()
    //   if (selectedTutor?.value && checkboxControl.value.size > 0) {
    //     const tutoringMapValues = props?.tutoringGroup?.filter(tg => tg.tutor.id === selectedTutor.value)
    //     if (tutoringMapValues.length) {
    //       tutoringMapValues.forEach(tutoringMapValue => {
    //         const tutoringGroup: ITutoringGroups = {
    //           alias: tutoringMapValue?.alias || '',
    //           powerBiAccount: tutoringMapValue?.account?.user || '',
    //           powerBiPass: tutoringMapValue?.account?.password || '',
    //           members: tutoringMapValue?.students?.map(st => st.id) || []
    //         }
    //         groupMap.set(tutoringMapValue.alias, tutoringGroup)
    //       })
    //     }
    //   }

    //   return groupMap
    // })

    const generateData = () => {
      const mapper = new Map()

      const tutoringMapValues = tutoringGroupRef.value.filter(tg => tg.tutor.id === selectedTutor.value)
      if (tutoringMapValues.length) {
        tutoringMapValues.forEach(tutoringMapValue => {
          const members = {}
          tutoringMapValue.students.forEach(member => {
            Object.assign(members, JSON.parse(`{"${member.id}": true}`))
          })
          const unassigned = props.students?.get(selectedTutor.value)?.filter(st => !tutoringMapValue.students.find(tst => st.user && tst.id === st.user.personId))
          unassigned?.forEach(notAssigned => {
            Object.assign(members, JSON.parse(`{"${notAssigned.user?.personId}": false}`))
          })
          mapper.set(tutoringMapValue.alias, members)
        })
        const empty = props.students?.get(selectedTutor.value)?.find(st => !st.user)
        if (!empty) {
          const emptyStudent = new Student()
          emptyStudent.profile = { name: '' }
          props.students?.get(selectedTutor.value)?.push(emptyStudent)
        }
      }

      return mapper
    }
    onMounted(() => {
      selectedTutor.value = Array.from(tutorsRef.value?.keys() || [])[0]
      checkboxControl.value = generateData()
      groups.value = generateGroupData()
    })

    watch(tutoringGroupRef, () => {
      selectedTutor.value = Array.from(tutorsRef.value?.keys() || [])[0]
      checkboxControl.value = generateData()
      groups.value = generateGroupData()
      return 0
    })
    const resetPowerBi = () => {
      teachersPowerBiRef.teachersPowerBiAccount = props.teachersPowerBi.teachersPowerBiAccount
      teachersPowerBiRef.teachersPowerBiPass = props.teachersPowerBi.teachersPowerBiPass
    }

    const savePowerBi = () => {
      console.log('savePowerBi')
    }

    const onChangeTutor = () => {
      checkboxControl.value = generateData()
      groups.value = generateGroupData()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeValueGroup = async (studentId: string, key: string) => {
      const checkboxValue = checkboxControl.value.get(key)[studentId]

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const tutoring = tutoringGroupRef.value.find(tg => tg.alias === key)!
      if (checkboxValue) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await CommissionTutorStudentsGroupApi.pushStudentInTutoringGroup(tutoring.id!, [studentId])
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await CommissionTutorStudentsGroupApi.deleteStudentsInTutoringGroup(tutoring.id!, [studentId])
      }
    }

    const dialogText = computed(() => {
      return ({
        title: isEditing.value ? 'Editar grupo' : 'Crear grupo'
      })
    })

    const onNewGroups = () => {
      displayNewGroup.value = true
    }

    const confirmModalGroup = async () => {
      if (!isEditing.value) {
        const tutoringGroup: TutorStudentGroups = {
          commissionId: props.courseId,
          commissionNumber: props.courseNumber,
          account: { user: editingAccountUserGroup.value, password: editingAccountPassGroup.value },
          alias: editingAliasGroup.value,
          tutor: {
            id: selectedTutor.value,
            name: '',
            lastName: '',
            email: '',
            country: ''
          },
          students: []
        }

        await CommissionTutorStudentsGroupApi.createGroup(tutoringGroup)
      } else {
        const updatePayload = {
          alias: editingAliasGroup.value,
          user: editingAccountUserGroup.value,
          password: editingAccountPassGroup.value
        }
        await CommissionTutorStudentsGroupApi.updateGroup(editingIdGroup.value, updatePayload)
      }
      displayNewGroup.value = false
      editingIdGroup.value = ''
      editingAliasGroup.value = ''
      editingAccountUserGroup.value = ''
      editingAccountPassGroup.value = ''
      emit('reloadStudents')
    }

    const closeModalGroup = () => {
      displayNewGroup.value = false
      editingAliasGroup.value = ''
    }

    const deleteGroup = async (col: { key: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const tutoring = tutoringGroupRef.value.find(tg => tg.alias === col.key)!
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await CommissionTutorStudentsGroupApi.deleteGroup(tutoring.id!)
      emit('reloadStudents')
    }

    const editGroup = async (col: { key: string }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const tutoring = tutoringGroupRef.value.find(tg => tg.alias === col.key)!
      isEditing.value = true
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      editingIdGroup.value = tutoring.id!
      displayNewGroup.value = true
      editingAliasGroup.value = tutoring.alias ?? ''
      editingAccountUserGroup.value = tutoring.account?.user ?? ''
      editingAccountPassGroup.value = tutoring.account?.password ?? ''
    }

    return {
      selectedTutor,
      teachersPowerBiRef,
      disabledButton,
      tutoringMap,
      groups,
      checkboxControl,
      dialogText,
      editingAliasGroup,
      editingAccountUserGroup,
      editingAccountPassGroup,
      editingIdGroup,
      onNewGroups,
      resetPowerBi,
      savePowerBi,
      onChangeTutor,
      changeValueGroup,
      EducationRouteNames,
      displayNewGroup,
      confirmModalGroup,
      closeModalGroup,
      deleteGroup,
      editGroup
    }
  }
})
